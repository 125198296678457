import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable} from 'rxjs';
import { IStorageProvider } from './storage-provider.interface';
import { IPerfil } from '../../interfaces/perfil.interface';
import { AppStatusService } from 'src/app/globalcampo/services/app-status.service';

@Injectable()
export class StorageWithPerfilService implements IStorageProvider {

  constructor(private readonly http: HttpClient, private readonly  _app: AppStatusService) { }

  get<T>(key: string): Observable<T> {
    const endpoints = this._app.getEndpoints(key);
    return this.http.get<IPerfil>(endpoints.getPerfil(key))
    .pipe(
      map((res) => JSON.parse(res?.datosPerfil ?? 'null') as T),
    );
  }

  set<T>(key: string, value: T): Observable<void> {
    const endpoints = this._app.getEndpoints(key);
    const toSave: IPerfil = {
      codPerfil: key,
      datosPerfil: JSON.stringify(value),
    };
    return this.http.put<IPerfil>(endpoints.PUT_PERFIL, toSave).pipe(
      map(() => undefined),
    );
  }

  delete(key: string): Observable<void> {
    const endpoints = this._app.getEndpoints(key);
    return this.http.delete<void>(endpoints.deletePerfil(key));
  }
}
