<div id="menu-list" [ngClass]="cssClass">
	@for (enlace of ccaMenu; track enlace) {
		@if (!enlace.children) {
			@if (!enlace.linkFn && enlace.link !== '/lonjas') {
				<ion-nav-link (click)="show(enlace,true)" (keydown.enter)="show(enlace,true)" class="list-link"  [ngClass]="enlace.cssClass">
					<ng-container [ngTemplateOutlet]="linkContent" [ngTemplateOutletContext]="{data: enlace}"></ng-container>
				</ion-nav-link>
			}
			@if (enlace.linkFn) {
				<ng-container [ngTemplateOutlet]="enlaceFn" [ngTemplateOutletContext]="{data: enlace}"></ng-container>
			}
			@if (enlace.link === '/lonjas') {
				<ion-nav-link [routerLinkActive]="['link-selected']" (click)="abrirURL()" (keydown.enter)="abrirURL()" class="list-link" [ngClass]="enlace.cssClass">
					@if (enlace.icon && (enlace.iconPosition==='before' || !enlace.iconPosition)) {
						<ion-icon class="link-icon icon-default" [routerLinkActive]="['icon-selected']" [name]="enlace.icon"></ion-icon>
						<ion-icon class="link-icon icon-hover" [routerLinkActive]="['icon-selected']" [name]="enlace.iconHover"></ion-icon>
					}
					@if (enlace.iconCsv && (enlace.iconPosition==='before' || !enlace.iconPosition)) {
						<ion-icon class="link-icon icon-default" [routerLinkActive]="['icon-selected']" [src]="enlace.iconCsv"></ion-icon>
						<ion-icon class="link-icon icon-hover" [routerLinkActive]="['icon-selected']" [src]="enlace.iconCsvHover"></ion-icon>
					}
					@if (!enlace.subTitle) {
						<ng-container [ngTemplateOutlet]="withoutSubtitle" [ngTemplateOutletContext]="{data: enlace}"></ng-container>
					}
					@if (!!enlace.subTitle) {
						<ng-container [ngTemplateOutlet]="withSubtitle" [ngTemplateOutletContext]="{data: enlace}"></ng-container>
					}
				</ion-nav-link>
			}
		} @else {
				<!-- SUBMENU -->
			<ion-nav-link class="list-link link-has-children">
				@if (!!enlace.icon) {
					<ion-icon class="link-icon icon-default" [class.icon-selected]="enlace._isActive" [name]="enlace.icon"></ion-icon>
					<ion-icon class="link-icon icon-hover" [class.icon-selected]="enlace._isActive" [name]="enlace.iconHover"></ion-icon>
				}
				@if (!!enlace.iconCsv) {
					<ion-icon class="link-icon icon-default" [class.icon-selected]="enlace._isActive" [src]="enlace.iconCsv"></ion-icon>
					<ion-icon class="link-icon icon-hover" [class.icon-selected]="enlace._isActive" [src]="enlace.iconCsvHover"></ion-icon>
				}
				{{ enlace.title | translate }}
			</ion-nav-link>

			@for (subenlace of enlace.children; track subenlace) {
			<div class="list-children">
				<ion-nav-link class="children-link " [routerLinkActive]="['link-selected']" (click)="show(subenlace,true)" (keydown.enter)="show(subenlace,true)">
				{{ subenlace.title | translate }}
				</ion-nav-link>
			</div>
			}
		}
	}
</div>

<ng-template #linkContent let-enlace="data">
	@if (enlace.icon && enlace.iconHover && (enlace.iconPosition==='before' || !enlace.iconPosition)) {
		<ion-icon class="link-icon icon-default" (click)="show(enlace)" (keydown.enter)="show(enlace)" [name]="enlace.icon"></ion-icon>
		<ion-icon class="link-icon icon-hover" (click)="show(enlace)" (keydown.enter)="show(enlace)" [name]="enlace.iconHover"></ion-icon>
	}
	@if (enlace.iconCsv && enlace.iconCsvHover && (enlace.iconPosition==='before' || !enlace.iconPosition)) {
		<ion-icon class="link-icon icon-default" (click)="show(enlace)" (keydown.enter)="show(enlace)" [src]="enlace.iconCsv"></ion-icon>
		<ion-icon class="link-icon icon-hover" (click)="show(enlace)" (keydown.enter)="show(enlace)" [src]="enlace.iconCsvHover"></ion-icon>
	}

	@if (enlace.icon && !enlace.iconHover && enlace.linkFn) {
		<ion-icon class="link-icon icon-default" (click)="show(enlace)" (keydown.enter)="show(enlace)" [name]="enlace.icon"></ion-icon>
	}

	@if (!enlace.subTitle) {
		<ng-container [ngTemplateOutlet]="withoutSubtitle" [ngTemplateOutletContext]="{data: enlace}"></ng-container>
	}
	@if (!!enlace.subTitle) {
		<ng-container [ngTemplateOutlet]="withSubtitle" [ngTemplateOutletContext]="{data: enlace}"></ng-container>
	}
	@if (enlace.icon && enlace.button) {
		<ion-button class="btn-s-accent-solid" >{{ cambiar |  translate }}</ion-button>
	}
</ng-template>

<ng-template #enlaceFn let-enlace="data">
	<button class="list-btn" (click)="openMenuAct()" (keydown.enter)="openMenuAct()">
		<ng-container [ngTemplateOutlet]="linkContent" [ngTemplateOutletContext]="{data: enlace}"></ng-container>
	</button>
</ng-template>

<ng-template #withoutSubtitle let-enlace="data">
    {{ enlace.title | translate }}
</ng-template>

<ng-template #withSubtitle let-enlace="data">
  <div>
    <div >{{ enlace.subTitle }}</div>
    <div> {{ enlace.title | translate }} </div>
  </div>
</ng-template>
