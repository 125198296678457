import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { urls } from '../../config/urls';
import { estadoAutorizacionSIEX } from '../../config/estado-autorizacion.config';
import { AppStatusService } from '../app-status.service';
import { ExplotacionesService } from '../cuadernos-de-campo/explotaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../loading/loading.service';


@Injectable({
  providedIn: 'root',
})
export class CcaSiexAuthorization {

  private readonly _autSiexAceptado: number = estadoAutorizacionSIEX.ACEPTADA.codEstadoAutorizacion;

  constructor(
    private readonly _app: AppStatusService,
    private readonly _router: Router,
    private readonly _explotaciones: ExplotacionesService,
    private readonly _loadingService: LoadingService,
    private readonly _translate: TranslateService,
  ) {}

  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const codIdentific = this._app.currentExplotacion?.codIdentific ?? '';
    const codInstalacion = this._app.currentExplotacion?.codInstalacion ?? undefined;

    if (!codIdentific) {
      return of(this._router.parseUrl(urls.cca.busqueda));
    }

    const loadingMessage = this._translate.instant('MODAL.LOADING.CARGANDO');
    return this._loadingService.loadWithMessage(     
      this._explotaciones.putSearchExplotacion(codIdentific, codInstalacion?.toString()).pipe(
        map((expl) => {
          if (!expl?.length) {
            return this._router.parseUrl(urls.cca.busqueda);
          }

          const codEstadoAutorizacion = expl[0].codEstadoAutorizacion;
          if (codEstadoAutorizacion === this._autSiexAceptado) {
            return true;
          } else {
            const tree = this._router.parseUrl(urls.cca.busqueda);
            tree.queryParams = _next.queryParams;
            return tree;
          }
        }),
      ),
      { message: loadingMessage },
    );
  }
}
