<ion-app>
  <ion-header id="app-header">
    <div class="header-logo" routerLink="/">
      <img class="logo-img" src="{{logoGlobalcampo}}" alt="" />
      <h1 class="logo-title">{{ 'GLOBALCAMPO' | translate }}</h1>
    </div>
    <gc-login-button [compactMode]="true"></gc-login-button>
  </ion-header>

  <ion-content id="app-content" forceOverscroll="false">
    <ion-split-pane  contentId="main" id="content-split-pane" when="md" [ngClass]="{'cca': menuprincipalLayout() === 'cca' || menuprincipalLayout() === 'componentes' || menuprincipalLayout() === 'settings'}">
      <ion-menu id="main-menu" menuId="main-menu" contentId="main"
        [swipeGesture]="false" 
        [ngClass]="{
          'main': menuprincipalLayout() === 'main',
          'cca': menuprincipalLayout() === 'cca',
          'hideMenu': menuprincipalLayout() === 'none',
          'compact': compactMenu,
        }"
      >

        <!-- MENU APLICACION -->
        @if (menuprincipalLayout() === 'main') {
          <app-menu [menuItems]="mainMenuHandler()" id="menu" cssClass="globalcampo"></app-menu>
        }

        <!-- MENU CCA -->
        @if (menuprincipalLayout() === 'cca') {
          <button class="ion-hide-md-down cca-btn" (click)="toggleBtnMenuCCA()">
            <ion-icon id="cca-btn-icon" slot="start" name="gc-double-arrow"></ion-icon>
          </button>
          <gc-menu-cca [compactMenu]="compactMenu"></gc-menu-cca>
        }

        <!-- MENU COMPONENTES -->
        @if (menuprincipalLayout() === 'componentes' || menuprincipalLayout() === 'settings' ) {
          <app-menu [menuItems]="componentesMenu" cssClass="cca"></app-menu>
        }
        
      </ion-menu>
      <ion-router-outlet id="main"></ion-router-outlet>
    </ion-split-pane>
  </ion-content>
</ion-app>
