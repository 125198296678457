import { Injectable } from '@angular/core';
import { ILoggerProvider } from './logger-provider.interface';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class LoggerProviderWithNgxLogger implements ILoggerProvider {

    constructor(private readonly logger: NGXLogger) {}

    log(...args: unknown[]) {
        this.logger.log(...args);
    }

    info(...args: unknown[]): void {
        this.logger.info( ...args);
    }

    error(...args: unknown[]) {
        this.logger.log( ...args);
    }

    warn(...args: unknown[]) {
        this.logger.warn( ...args);
    }

}
