import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { Subject, map, takeUntil } from 'rxjs';
import { LoginService } from 'src/app/globalcampo/services/login.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'gc-login-button',
  templateUrl: './login-button.component.html',
  styleUrls: ['./login-button.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule]
})
export class LoginButtonComponent  implements OnInit, OnDestroy {

  /**
   * Define si se visualiza en modo compacto
   */
  private _compactMode: boolean = false;

  @Input()
  public get compactMode(): boolean {
    return this._compactMode;
  }
  public set compactMode(value: boolean) {
    this._compactMode = value;
  }

  private _buttonText: string = 'LOGIN.INICIAR_SESION';

  @Input()
  set buttonText(text: string){
    this._buttonText = text;
  }
  get buttonText(){
    return this._buttonText;
  }

  private readonly _unsubs: Subject<unknown> = new Subject();

  private _usuario: string | null = null;
  public get usuario(): string | null {
    return this._usuario;
  }
  public set usuario(value: string | null) {
    this._usuario = value;
  }


  constructor(private readonly _login: LoginService, private readonly _modalCtrl: ModalController) { }

  ngOnInit() {
    this._login.isLoggedIn$
      .pipe(
        takeUntil(this._unsubs),
        map((dato) => dato?.name ?? null),
      )
      .subscribe(async(userName) => {
        this._usuario = userName;
          const modal = await this._modalCtrl.getTop();
        if (userName && modal) {
           modal.dismiss();
        }
      });
  }

  /**
   * Hace la apertura del Login
   */
  async openLogin(){
    this._login.loginRequest();
  }

  /**
   * Cierra la sesión del usaurio.
   * Pregunta si queremos realizar el cierre de sesión
   */
  async closeSession(){
    this._login.logOut();
  }

  loginOrNot(): void {
    if(!this._usuario) {
      this.openLogin();
    }
    else{
      this.closeSession();
    }
  }

  ngOnDestroy(): void {
    this._unsubs.next(null);
    this._unsubs.complete();
  }
}
