import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { activadesCCA } from 'src/app/globalcampo/config/listado-actividades-cca.config';
import { IActividadCCA } from 'src/app/globalcampo/interfaces/actividad-cca.interface';
import { ToolbarComponent } from '../../toolbar/toolbar.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-actividades-menu',
  templateUrl: './actividades-menu.component.html',
  styleUrls: ['./actividades-menu.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, ToolbarComponent, TranslateModule]
})
export class ActividadesMenuComponent {

  private _actividades: IActividadCCA[] = this.sortActividades(activadesCCA);

  public get actividades(): IActividadCCA[] {
    return this._actividades;
  }

  /**
   * Obtenemos las actividades principales.
   */
  public get actividadesPrincipales(): IActividadCCA[] {
    return this._actividades.filter((actividad) => actividad?.principal);
  }

  /**
   * Obtenemos las actividades secundarias.
   */
  public get actividadesSecundarias(): IActividadCCA[] {
    return this._actividades.filter((actividad) => !actividad?.principal);
  }

  constructor(private modalCtrl: ModalController) { }

  async dismisDialog() {
    await this.modalCtrl.dismiss();
  }

  /**
   * Se selecciona un elemento
   * @param el la actividad seleccionada
   */
  async selected(el: IActividadCCA) {
    await this.modalCtrl.dismiss(el);
  }

  /**
   * Ordena las actividades.
   * @param actividades Actividades que se desean ordenar.
   * @returns Devuelve las actividades ordenadas.
   */
  sortActividades(actividades: IActividadCCA[]): IActividadCCA[] {
    return actividades.sort((a, b) => this.sortActividadPrioridad(a, b));
  }
  
  /**
   * Forma de ordenación para las actividades por el orden de prioridad.
   * @param a Actividad a del sort.
   * @param b Actividad b del sort.
   * @returns Devuelve un numero positivo, 0 o negativo para indicar el orden.
   */
  sortActividadPrioridad(a: IActividadCCA, b: IActividadCCA): number {
    return (a.ordenPrioridad - b.ordenPrioridad);
  }

  /**
   * Comprueba si el link de actividad tiene url desconocida
   * @param actividad 
   * @returns 
   */
  comprobarUrl(actividad: IActividadCCA): boolean {
    return actividad.link.includes('unknow');
  }

}
