<div class="cca-menu" [ngClass]="{ 'compact': compactMenu() }">
  @if(showMenuExplotacion){
    <div class="menu-header">
      <ion-icon class="header-icon" name="gc-cuaderno-digital"></ion-icon>
      <h2 class="header-title">{{ 'CCA.MENU.DIGITAL' | translate }}</h2>
    </div>
  }
  <div class="menu-content">
    @if(showMenuExplotacion){
      <!-- <div class="content-user">
        <a class="user-profile">
          <span class="profile-img">
            <span class="img">{{ img | uppercase }}</span>
          </span>
          <span class="profile-data">
            <p class="data-label">{{ 'bienvenido,' | titlecase }}</p>
            <h6 class="data-text">{{ usuario | titlecase }}</h6>
          </span>
        </a>
        <a class="user-notif">
          <span class="notif-label">
            <ion-icon class="label-icon" name="notifications-outline"></ion-icon>
            <p class="label-text">{{ 'notificaciones' | titlecase }}</p>
          </span>
          <span class="notif-data">0</span>
        </a>
      </div> -->
      <div class="content-btn">
          <button class="btn" (click)="openMenuAct()" (keydown.enter)="openMenuAct()">
            <ion-icon class="btn-icon" slot="start" name="add"></ion-icon>
            <span class="btn-text">{{ 'CCA.MENU.NUEVA_ACT' | translate }}</span>
          </button>
      </div>
      <div class="content-nav">
        @for(navLink of navLinks; track navLink) {
          <a class="nav-link" routerLink="{{navLink.link}}" routerLinkActive="link-selected" (click)="navigationUrl(navLink, true)" (keydown.enter)="navigationUrl(navLink,true)" >
            <span class="link-icon">
              <ion-icon class="icon-outline" name="{{ navLink.icon }}"></ion-icon>
              <ion-icon class="icon-solid" name="{{ navLink.iconHover }}"></ion-icon>
            </span>
            <h5 class="link-text">{{ navLink.title | translate }}</h5>
          </a>
        }
      </div>
    }
    <div class="content-switch">
      <a class="switch-link">
        <span class="link-data">
          <p class="data-label">{{ instalacionLabel | translate }}</p>
          <h4 class="data-text">{{ instalacion | uppercase }}</h4>
        </span>
        @if(showCambioInstalacion) {
          <ion-button class="btn-s-accent-outline" (click)="navigationUrl(switchInstalacionLink, true)" (keydown.enter)="navigationUrl(switchInstalacionLink,true)">{{ 'CCA.MENU.CAMBIAR' | translate }}</ion-button>
        }
      </a>
      @if(instalacion !== '') {
        <a class="switch-link">
          <span class="link-data">
            <p class="data-label">{{ explotacionLabel | translate }}</p>
            <h4 class="data-text">{{ explotacion | uppercase }}</h4>
          </span>
          @if(showCambioExplotacion) {
            <ion-button class="btn-s-accent-outline" (click)="navigationUrl(switchIExplotacionLink, true)" (keydown.enter)="navigationUrl(switchIExplotacionLink,true)">{{ 'CCA.MENU.CAMBIAR' | translate }}</ion-button>
          }
        </a>
      }
    </div>
  </div>
  <div class="menu-footer">
    <a class="footer-link" (click)="navigationUrl(footerExitLink, true)" (keydown.enter)="navigationUrl(footerExitLink,true)">
      <ion-icon class="link-icon" name="gc-out-arrow"></ion-icon>
      <a class="link-text">{{ footerExitLink.title | translate }}</a>
    </a>
  </div>
</div>
