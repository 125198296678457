import { environment } from 'src/environments/environment';

const apiVx = 'v1';
export const usersEndPoints = {

    me: `${environment.apiURL}ctl/users/${apiVx}/me`,
    registro: `${environment.apiURL}ctl/users/${apiVx}/registro`,
    listado: `${environment.apiURL}ctl/users/${apiVx}/usuarios/aplicacion`,

}
