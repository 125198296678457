import { urls } from 'src/app/globalcampo/config/urls';
import { IMenu } from 'src/app/globalcampo/interfaces/menu-item.interface';

 export const urlComponentes = {
    alerts: 'componentes/alerts',
    dialogItemList: 'componentes/dialog-item-list',
    cabeceraGlobalcampo: 'componentes/cabecera-globalcampo',
    fechaHora :'componentes/fechaHora',
    iframeViewer :'componentes/iframeviewer',
    inputs: 'componentes/input-custom-tester',
 };

 export const menuComponentes: IMenu[] = [
    { link: urls.inicio, icon: 'home-outline', cssClass:'margin-bottom', title: 'CCA.MENU.SALIR' },
    { title: 'Alerts', link: urlComponentes.alerts},
    // { title: 'Alerts', linkFn: (): ILoadableComponent => ({component: AlertsTesterPage})},
    { title: 'Dialog Item List', link: urlComponentes.dialogItemList},
    { title: 'Group wrapper', link: urlComponentes.cabeceraGlobalcampo},
    { title: 'Fecha Hora', link: urlComponentes.fechaHora},
    { title: 'Iframe Viewer', link: urlComponentes.iframeViewer},
    {title: 'Inputs', link: urlComponentes.inputs},
]

