import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Injectable } from '@angular/core';
import { urls } from '../../config/urls';
import { AppStatusService } from '../app-status.service';
import { tokenCCAString } from '../../config/app-config';
import { Observable, catchError, map, of, tap, switchMap, forkJoin } from 'rxjs';
import { ExplotacionesService } from '../cuadernos-de-campo/explotaciones.service';
import { IInstalacionExplotacionDataFromToken } from '../../interfaces/security/instalacion-explotacion-data-from-token.interface';

@Injectable({
    providedIn: 'root',
})
export class CcaInstalacionExplotacionRequired {

    constructor(private readonly app: AppStatusService, private readonly router: Router, private readonly explotaciones: ExplotacionesService) {}

    canActivate( _next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        let res:Observable<UrlTree|boolean> = of(true);
        // Si no hay instalación seleccionada, lo sacamos a la selección de la instalación
        const token = this.app.ccaToken ?? _next?.queryParamMap?.get(tokenCCAString);
        if(token){
            // Si no hay explotación intentamos obtenerlo a partir del token
            if(!this.app.currentExplotacion){
                res = this.explotaciones.getDataFromTokenCca(token)
                    .pipe(
                        switchMap((data: IInstalacionExplotacionDataFromToken) => {
                            const {instalacion, codIdentific} = data;
                            if(instalacion && codIdentific){
                                this.app.ccaToken = token;
                                this.app.setCurrentInstalacion(instalacion);
                                // obtenemos la información de la explotación
                                return forkJoin({
                                    explotacion: this.explotaciones.getDatosExplotacion(codIdentific),
                                }).pipe(
                                    tap((_info) => {
                                        this.app.setCurrentExplotacion(_info.explotacion);
                                    }),
                                    map((expData) => !!expData.explotacion ),
                                );
                            }
                            else{
                                throw new Error();
                            }
                        }),
                        catchError(() => {
                            this.app.setCurrentInstalacion(null);
                            console.error('Se ha producido un error en la obtención de la entidad y explotación desde el token');
                            return of(this.router.parseUrl(urls.cca.instalaciones));
                        }),
                    );
            }
        }
        else if (!this.app.currentInstalacion) {
            const tree = this.router.parseUrl(urls.cca.instalaciones);
            res = of(tree);
        }
        else if (!this.app.currentExplotacion) {
            const tree = this.router.parseUrl(urls.cca.busqueda);
            tree.queryParams = _next.queryParams;
            res = of(tree);
        }
        // Incorporación del token
        if (!_next?.queryParamMap?.has(tokenCCAString)) {
            console.log(tokenCCAString + ' NOT found');
            const tree = this.router.parseUrl(_state?.url);
            tree.queryParams[tokenCCAString] = token;
            res = of(tree);
        }

        return res;
    }
}
