import { Inject, Injectable } from '@angular/core';
import { IProfileService } from './profile-service.interface';
import { BehaviorSubject, Observable, catchError, from, map, of, switchMap, tap, take} from 'rxjs';
import { IProfile, profileDefaults } from './profile.interface';
import { StorageKeys } from '../../config/storage-keys.config';
import { STORAGE_PROVIDER } from '../storage/storage-provider.token';
import { IStorageProvider } from '../storage/storage-provider.interface';

export const PROFILE_KEY = StorageKeys.PROFILE.name;
@Injectable({
  providedIn: 'root',
})
export class ProfileServiceWithPerfilPrefers implements IProfileService {

  private readonly _profileChanged: BehaviorSubject<IProfile|null> = new BehaviorSubject<IProfile|null>(null);

  constructor(@Inject(STORAGE_PROVIDER) private readonly storage: IStorageProvider) { }

    /**
     * Reads the user's profile from the service
     * @returns
     */
     readProfile(): Observable<IProfile|null>{
      return this.preferencesRead()
      .pipe(
        switchMap( (res) => {
          let profile: IProfile|null = null;
          try {

            if (res) {
              profile = JSON.parse(res as unknown as string) as IProfile;
            }

            if(profile){
                return of({ ...this.getProfileDefaults(), ...profile});
            }
            const newProfile: IProfile = { ...this.getProfileDefaults()};
            this.saveProfile(newProfile);
            return of(newProfile);

          } catch (error) {
            return of(null);
          }
        }));
    }

    /**
     * Lectura del profile desde el storage
     * @returns
     */
    private preferencesRead():Observable<IProfile>{
      return from(this.storage.get(PROFILE_KEY)) as Observable<IProfile>;
    }
    private getProfileDefaults(): IProfile {
      return profileDefaults;
    }

    getProfile(): Observable<IProfile|null>{
      // if we already have a value, send it instead of reading it again from the preferences
      if(this._profileChanged.value){
        this._profileChanged.asObservable();
      }
      return this.readProfile().pipe(
        switchMap((profile) => {
           if(profile){
            this._profileChanged.next(profile);
           }
           return this._profileChanged.asObservable();
        }),
        catchError( (_err) => of(null)),
      );
    }


    saveProfile(profile: IProfile): Observable<IProfile|null> {
      return from(this.storage.set(PROFILE_KEY, JSON.stringify(profile)))
      .pipe(
        tap(() => {
          this._profileChanged.next(profile); }),
        map(() => profile),
        catchError( (_err) => of(null)),
      );
    }

    patchProfile(profile: IProfile, _de: string): Observable<IProfile|null> {
      let obs;
      if (this._profileChanged.value) {
        obs = this.patchAndSaveProfile(this._profileChanged.value, profile);
      } else {
        obs = this.readProfile().pipe(
          take(1),
          switchMap((existingProfile) => {
            if (existingProfile) {
              return this.patchAndSaveProfile(existingProfile, profile);
            }
            const newProfile: IProfile = { ...this.getProfileDefaults()};
            return this.patchAndSaveProfile(newProfile, profile);
          }),
        );
      }

      return obs.pipe(switchMap((updatedProfile) => of(updatedProfile)));

    }

    private patchAndSaveProfile(current: IProfile, newData: IProfile): Observable<IProfile|null> {
          const patchedProdfile = {...current, ...newData};
          return this.saveProfile(patchedProdfile as IProfile);
    }


    private preferencesRemove(): Observable<void>{
      return from(this.storage.delete(PROFILE_KEY));
    }


    deleteProfile(): Observable<boolean>{
      return this.preferencesRemove().pipe(
        tap((_res) => {
          this._profileChanged.next(null);
        }),
        map((_res) => true),
        catchError( (_err) => of(false)),
      );
    }

}
