import { IMenu } from '../interfaces/menu-item.interface';
import { ASSETS_PATHS } from './assets-paths.config';
import { urls } from './urls';

export const menuAplicacion: IMenu[] = [
    { title: 'MENU.INICIO', link: urls.inicio, icon: 'home-outline', iconHover: 'home' },
    { title: 'MENU.CCA', link: urls.cca.instalaciones, icon: 'book-outline', iconHover: 'book' },
    { title: 'MENU.GLOBALCLIMA', link: urls.globalclima, icon: 'rainy-outline', iconHover: 'rainy' },
    { title: 'MENU.LONJAS', link: urls.lonjas, icon: 'bar-chart-outline', iconHover: 'bar-chart' },
    { title: 'MENU.PAC_AYUDAS', link: null , icon: 'people-outline', iconHover: 'people' },
    { title: 'MENU.NOTICIAS', link: null, icon: 'newspaper-outline', iconHover: 'newspaper' },
    { title: 'MENU.SAR', link: null, icon: 'water-outline', iconHover: 'water' },
    { title: 'MENU.GICOOP_PLUS', link: null, iconCsv: ASSETS_PATHS.gicoop_outline },
    { title: 'MENU.PERFIL', link: urls.perfil, icon: 'person-outline', iconHover: 'person'},
    { title: 'ABRETE SESAMO', link: 'componentes/input-custom-tester'},

]

export type menuGCType = {[key: string]: IMenu};

export const menuCCA: menuGCType = {
  dashboard: { link: urls.cca.dashboard, icon: 'gc-home-outline', iconHover: 'gc-home', title: 'CCA.MENU.PANEL_CONTROL'},
  actividades: { link: urls.cca.actividades, icon: 'gc-clipboard-outline', iconHover: 'gc-clipboard', title: 'CCA.MENU.ACTIVIDADES_REALIZADAS'},
  titular: { link: urls.cca.titular, icon: 'gc-farmer-outline', iconHover: 'gc-farmer', title: 'CCA.MENU.TITULAR'},
  explotacion: { link: urls.cca.general, icon: 'gc-barn-outline', iconHover: 'gc-barn', title: 'CCA.MENU.EXPLOTACION'},
  trabajadores: { link: urls.cca.trabajadores, icon: 'gc-users-outline', iconHover: 'gc-users', title: 'CCA.MENU.TRABAJADORES'},
  parcelario: { link: urls.cca.parcelas, icon: 'gc-map-outline', iconHover: 'gc-map', title: 'CCA.MENU.PARCELARIO'},
  instalaciones: { link: urls.cca.instalaciones, title: 'CCA.MENU.INSTALACION'},
  explotaciones: { link: urls.cca.busqueda, title: 'CCA.MENU.EXPLOTACION'},
  inicio: { link: urls.inicio, title: 'CCA.MENU.SALIR'},
};