import { Component, ElementRef, EventEmitter, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { IonicModule, MenuController, ModalController, NavController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { AppStatusService } from 'src/app/globalcampo/services/app-status.service';
import { IMenu } from 'src/app/globalcampo/interfaces/menu-item.interface';
import { ActividadesService } from 'src/app/globalcampo/services/cuadernos-de-campo/actividades.service';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, RouterModule, TranslateModule],
})
export class MenuComponent implements OnInit, OnChanges, OnDestroy {

  private readonly _modal = inject(ModalController);

  /**
   * Input que contiene los elementos items del menu
   */
  @Input() menuItems: IMenu[] = [];

  @Input() cssClass: string | null = null;

  @Output()  menuSelected: EventEmitter<IMenu> = new EventEmitter();

  public selectedUrl: string = '';

  private readonly _unsubs = new Subject();

  /**
   * Opciones de menú para el menú lateral de Cuadernos de campo
   */
  private _ccaMenu: IMenu[] = [];
  public get ccaMenu(): IMenu[] {
    return this._ccaMenu;
  }

  // literal que aparece en boton cambiar explotacion y entidad gestora
  public cambiar: string = 'CCA.MENU.CAMBIAR';

  // Valor que guarda las variables CSS para la propiedad de padding
  private _padding: string = '';

  constructor( private readonly route: Router,
                        private readonly appStatusService: AppStatusService,
                        private readonly _menuController: MenuController,
                        private readonly _elementRef: ElementRef,
                        private readonly _navCtrl: NavController,
                        private readonly _actividadesService: ActividadesService) { }

  ngOnInit() {
    this.route.events.
      pipe(takeUntil(this._unsubs))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.selectedUrl = this.route.url;
        }
        if (this.selectedUrl !== this.route.url) {
          this.activateCurrentMenuItem();
        }
      });

      /**
       * Asigna un valor de padding al componente menu dependiendo de si se encuentra en una modal o no
       */
      if(this.cssClass?.includes('modal')) {
        this._padding =  'var(--gc-size-padding-xl) var(--gc-size-padding-xl) var(--gc-size-padding-xl) var(--gc-size-padding-xl)';
      } else {
        this._padding =  'calc(var(--gc-size-padding-xl) + var(--ion-safe-area-top)) var(--gc-size-padding-xl) var(--gc-size-padding-xl) var(--gc-size-padding-xl)';
      }
      this._elementRef.nativeElement.style.setProperty('--gc-size-padding-menu', this._padding);
  }

  /**
   * Método que marca como activo el item de menú que se encuentra en la URL actual
   */
  activateCurrentMenuItem() {
    this.selectedUrl = this.route.url;
    // se busca el elemento del menú que contiene la ruta que se habia seleccionado para asi reestablecer el estado anterior del menú
    const selectedURlWithoutQueryParams = this.selectedUrl.split('?')[0];
    const itemSelectedUrl = this.findSelectedItem(selectedURlWithoutQueryParams, this._ccaMenu);
    // si se encuentra el _selectedUrl se marca en el menú como activo
    if (itemSelectedUrl) {
      this.markActiveElement(itemSelectedUrl);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['menuItems']) {
      this._ccaMenu = this.menuItems;
      this.ccaMenu.forEach((element) => element._isActive = false);
    }
    // Se define la URL para estar disponible ante cambios de Inputs
    if (!this.selectedUrl && !!this.route.url) {
      this.activateCurrentMenuItem();
    }

  }

  ngOnDestroy(): void {
    this._unsubs.next(null);
    this._unsubs.complete();
  }


  /**
   * Método para realizar una busqueda recursiva en un array de objetos para encontrar la url === link que se le pasa por parametro. Devuelve el item de menú que contiene esta url
   * @param url url o link que se queire buscar en el arry de objetos de Menu
   * @param menu Array de items de menu
   * @returns devuelve el item de menú que contien el url o undefined si no existe
   */
  findSelectedItem(url: string, menu: IMenu[]): IMenu | undefined {
    for (const item of menu) {
      if (item.link && url.startsWith(item.link)) {
        return item;
      } else if (item.children) {
        const childItem = this.findSelectedItem(url, item.children);
        if (childItem) {
          return item;
        }
      }
    }
    return undefined;
  }


  async show(menu: IMenu, rootNavigation: boolean = false) {
    this.menuSelected.emit(menu);
    const isOpen = await this._menuController.isOpen();
    if (isOpen) {
      await this._menuController.close();
    }
    this.markActiveElement(menu);
    if (rootNavigation) {
      await this._navCtrl.navigateRoot([menu.link]);
    } else {
      await this.route.navigate([menu.link]);
    }
  }

  /**
   * Metodo que marca como activo un elemento padre si se selecciona uno de los elementos hijos del menú
   * @param item item del menú que se a clicado en la visual
   */
  markActiveElement(item: IMenu) {
    this.ccaMenu.forEach((i) => i._isActive = false);
    item._isActive = true;
  }

  /**
   * Abrir url lonjas
   */
   async abrirURL() {
      const url = 'https://globalcampo.es/appweb/lonjas';
      window.open(url, '_self', 'noopener');
  }


  /**
   * Abre el menú de actividades.
   */
  openMenuAct() {
    this._actividadesService.openActividadesMenu();
    this._menuController.close();
  }


}
