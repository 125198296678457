import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { IonicSlides, ModalController } from '@ionic/angular';
import { ASSETS_PATHS } from 'src/app/globalcampo/config/assets-paths.config';
import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  standalone: true,
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class WelcomeComponent  implements OnInit {

  swiperModules = [IonicSlides];
  public readonly logoGlobalcampo: string = ASSETS_PATHS.logoGlobalcampo;

  imageSlide2 = ASSETS_PATHS.tractor_inicio;
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

  slideChanged(event: any): void {
    console.log(event);
  }

  closeModal(): void {
    this.modalCtrl.dismiss();
  }

}