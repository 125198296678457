import { CommonModule } from '@angular/common';
import { Component, input, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { IonicModule, MenuController, NavController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { menuCCA, menuGCType } from 'src/app/globalcampo/config/menus-app.config';
import { IExplotacion } from 'src/app/globalcampo/interfaces/explotaciones/explotacion.inteface';
import { IInstalacion } from 'src/app/globalcampo/interfaces/instalaciones/instalacion.interface';
import { IMenu } from 'src/app/globalcampo/interfaces/menu-item.interface';
import { IUser } from 'src/app/globalcampo/interfaces/user.interface';
import { AppStatusService } from 'src/app/globalcampo/services/app-status.service';
import { ActividadesService } from 'src/app/globalcampo/services/cuadernos-de-campo/actividades.service';
import { GlobalcampoService } from 'src/app/globalcampo/services/cuadernos-de-campo/globalcampo.service';

@Component({
  selector: 'gc-menu-cca',
  templateUrl: './menu-cca.component.html',
  styleUrls: ['./menu-cca.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, RouterModule, TranslateModule],
})
export class MenuCcaComponent implements OnInit {

  /**
   * Indica si el menu tiene compact attributes o no
   */
  compactMenu = input<boolean>();

  private readonly _unsubs: Subject<unknown> = new Subject();

  private readonly _labelSelectInstalacion: string = 'CCA.MENU.SEL_INSTALACION';

  /**
   * La etiqueta para la instalación.
   */
  private _instalacionLabel: string = this._labelSelectInstalacion;
  public get instalacionLabel(): string {
    return this._instalacionLabel;
  }
  /**
   * Representa la instalación.
   */
  private _instalacion: string = '';
  public get instalacion(): string | null {
    return this._instalacion;
  }
  /**
   * Indica si se muestra u oculta el botón de cambio de instalación.
   */
  private _showCambioInstalacion: boolean = false;
  public get showCambioInstalacion(): boolean {
    return this._showCambioInstalacion;
  }

  /**
   * La etiqueta para la explotación.
   */
  private _explotacionLabel: string = '';
  public get explotacionLabel(): string {
    return this._explotacionLabel;
  }
  /**
   * Representa la explotación.
   */
  private _explotacion: string = '';
  public get explotacion(): string | null {
    return this._explotacion;
  }
  /**
   * Indica si se muestra u oculta el botón de cambio de explotación.
   */
  private _showCambioExplotacion: boolean = false;
  public get showCambioExplotacion(): boolean {
    return this._showCambioExplotacion;
  }

  /**
   * Indica si se muestra u oculta el menú de "Explotación".
   */
  private _showMenuExplotacion: boolean = false;
  public get showMenuExplotacion(): boolean {
    return this._showMenuExplotacion;
  }

  /**
   * Representa la imagen (actualmente la primera letra del nombre) del usuario
   */
  private _img: string = '';
  public get img(): string | null {
    return this._img;
  }

  /**
   * Representa el usuario
   */
  private _usuario: string = '';
  public get usuario(): string | null {
    return this._usuario;
  }

  /**
   * Representa los nav links del menu
   */
  private readonly _navLinks:IMenu[] = this.manageNavLinks();
  public get navLinks(): IMenu[] {
    return this._navLinks;
  }

  /**
   * Representa el switch link de instalación.
   */
  private _switchInstalacionLink!: IMenu;
  public get switchInstalacionLink(): IMenu {
    return this._switchInstalacionLink;
  }

  /**
   * Representa el switch link de explotación.
   */
  private _switchIExplotacionLink!: IMenu;
  public get switchIExplotacionLink(): IMenu {
    return this._switchIExplotacionLink;
  }

  /**
   * Representa el link de salida del footer.
   */
  private _footerExitLink!: IMenu;
  public get footerExitLink(): IMenu {
    return this._footerExitLink;
  }

  constructor (  private readonly _router: Router,
                          private readonly _menuController: MenuController,
                          private readonly _navCtrl: NavController,
                          private readonly _gcService: GlobalcampoService,
                          private readonly _actividadesService: ActividadesService,
                          private readonly _appStatusService: AppStatusService ) { }


  ngOnInit() {

    this.manageLinks();

    this._appStatusService.instalacionChanged$
    .pipe(
      tap(() => {
        if (this._appStatusService.cantidadInstalaciones > 1) {
          this._showCambioInstalacion = !this._showCambioInstalacion;
        }
      }),
      tap((instalacion) => {
        this.generateInstValues(instalacion);
      }),
    )
    .subscribe();

    this._appStatusService.explotacionChanged$
    .pipe(
      tap( (explotacion) => {
        this.generateExpValues(explotacion);
      }),
    )
    .subscribe();

    this._gcService.getMe()
      .pipe(
        takeUntil(this._unsubs),
        tap((datos) => {
          this.generateUserData(datos);
        }),
      )
      .subscribe();
  }

  /**
   * Genera los valores para los switch basado en la instalacion proporcionada.
   *
   * @param instalacion - El objeto explotacion para generar los valores.
   */
  public generateInstValues(instalacion: IInstalacion | null) {
    if (instalacion) {
      this._instalacion = instalacion.nombreInstalacion;
      this._instalacionLabel = 'CCA.MENU.INSTALACION';
    } else {
      this._instalacion = '';
      this._instalacionLabel = this._labelSelectInstalacion;
    }
  }

  /**
   * Genera los valores para el componente menu-cca basado en la explotacion proporcionada.
   *
   * @param explotacion - El objeto explotacion para generar los valores.
   */
  public generateExpValues(explotacion: IExplotacion | null) {
    if (explotacion) {
      this._showMenuExplotacion = true;
      this._showCambioExplotacion = !this._showCambioExplotacion;
      this._explotacion = explotacion.codIdentific;
      this._explotacionLabel = 'CCA.MENU.EXPLOTACION';
    } else {
      this._showMenuExplotacion = false;
      this._showCambioExplotacion = false;
      this._explotacion = '';
      this._explotacionLabel = 'CCA.MENU.SEL_EXPLOTACION';
    }
  }

    /**
     * Genera los datos del usuario basados en el objeto IUser proporcionado.
     *
     * @param datos - El objeto IUser que contiene los datos del usuario.
     */
    public generateUserData(datos: IUser) {
      const name = datos?.name ?? '';
      const surname = datos?.apellidos ?? '';
      this._usuario = `${name} ${surname}`;
      this._img = name.substring(0, 1);
    }

    /**
     * Recupera un array de elementos de menú de navegación.
     *
     * @returns Un array de objetos `IMenu` que representan los elementos de menú.
     */
    public manageNavLinks(): IMenu[] {
      const menu: menuGCType = menuCCA;
      const dashboard = menu['dashboard'];
      const actividades = menu['actividades'];
      const explotacion = menu['explotacion'];
      const trabajadores = menu['trabajadores'];
      const parcelario = menu['parcelario'];
      return [dashboard, actividades, explotacion, trabajadores, parcelario];
    }

    /**
     * Gestiona los enlaces para el componente de menú.
     */
    public manageLinks() {
      const menu: menuGCType = menuCCA;
      this._switchInstalacionLink = menu['instalaciones'];
      this._switchIExplotacionLink = menu['explotaciones'];
      this._footerExitLink = menu['inicio'];
    }

    /**
     * Abre el menú de actividades y cierra el controlador de menú.
     */
    public openMenuAct() {
      this._actividadesService.openActividadesMenu();
      this._menuController.close();
    }

    /**
     * Navega a la URL especificada.
     *
     * @param menu - El objeto de menú que contiene la URL a la que navegar.
     * @param rootNavigation - Un booleano que indica si se debe realizar una navegación raíz.
     * @returns Una Promesa que se resuelve cuando se completa la navegación.
     */
    async navigationUrl(menu?: IMenu, rootNavigation: boolean = false) {
      const link: string = menu?.link ?? null ?? '';

      this._menuController.close();

      if (rootNavigation) {
        await this._navCtrl.navigateRoot([link]);
      } else {
        await this._router.navigate([link]);
      }

    }

}
