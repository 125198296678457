/**
 * Valores por defecto
 */
export const profileDefaults: IProfile = {
    theme: 'default',
    selectedMapType: 'terrain',
    lastAccess: new Date,
    mode: 'light',
    test: false,

  };

  export type themeAllowed = 'default' | 'globalcampo';
  export type mapsViewType = 'roadmap' | 'satellite' | 'hybrid' | 'terrain';
  export type modosVisualizacion = 'light' | 'dark' | 'system';
  export interface IProfile {
    userData?: any;
    mode?: modosVisualizacion;
    lastAccess?: Date | null;
    ModalWelcome?: Date;
    selectedMapType: mapsViewType;
    theme: themeAllowed;
    test: boolean;
  }
