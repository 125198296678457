@if (!compactMode) {


  @if (!usuario) {
    <ion-button class="btn-m-accent-solid" (click)="openLogin()" (keydown.enter)="openLogin()">{{ buttonText | translate }}</ion-button>
  }

  @if (!!usuario) {
    <ion-button class="btn-s-accent-outline" (click)="closeSession()" (keydown.enter)="closeSession()">{{ 'LOGIN.CERRAR_SESION' | translate }}</ion-button>
  }


}

@if (compactMode) {
    <ng-container *ngTemplateOutlet="userAvatar"></ng-container>
}

<ng-template #userAvatar>
    <ion-button  class="btn-s-accent-clear" (click)="loginOrNot()" (keydown.enter)="loginOrNot()">
      <ion-icon slot="end" aria-hidden="true" name="person"></ion-icon>
      @if (!usuario) {
        {{ buttonText | translate }}
      }
      <ng-container>{{ usuario }}</ng-container>
    </ion-button>
</ng-template>
