<swiper-container [modules]="swiperModules" [loop]="false" [pagination]="true" [navigation]="true" (slidechange)="slideChanged($event)">
  <swiper-slide>
   <div class="slide slide1">
     <img [src]="logoGlobalcampo" width="200px"/>
        <div class="text">
        ¡Bienvenido a Globalcampo!
        </div>
   </div>
  </swiper-slide>
  <swiper-slide>
   <div class="slide">
       <img [src]="imageSlide2" width="300px"/>
        <div class="text">
        Mantén tu cuaderno de campo al día, sólo por ser cliente de Globalcaja
        </div>
   </div>
    </swiper-slide>
  <swiper-slide>
   <div class="slide">
        <div class="text">
        </div>
        <ion-button class="btn-m-accent-solid" (click)="closeModal()" (keydown.enter)="closeModal()">¡Comenzar!</ion-button>
  </div>
  </swiper-slide>
</swiper-container>
