import { Routes } from '@angular/router';
import { urls } from './globalcampo/config/urls';
import { UserLoggedInGuard } from './globalcampo/services/guards/user-logged-in.guard';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./components/menu-tabs/tabs.routes').then((m) => m.routes),
  },
  {
    path: 'cca',
    canActivate: [UserLoggedInGuard],
    loadChildren: () => import('./pages/cca/cca.routes').then((m) => m.routes),
  },
  {
    path: 'globalclima',
    loadComponent: () => import('./pages/globalclima/globalclima.page').then( (m) => m.GlobalclimaPage),
  },
  {
    path: 'trabajadores',
    loadComponent: () => import('./pages/cca/trabajadores/trabajadores.page').then((m) => m.TrabajadoresPage),
  },
  {
    // aplico el replace para quitarle el / a la ruta definida
    path: urls.internalViewComponents.replace('/',''),
    // canActivate: [notInProdAndPreEnvironmentGuard],
    loadChildren: () => import('./pages/_components/components.routes').then((m) => m.routes),
  },
  {
    path: 'settings',
    canActivate: [UserLoggedInGuard],
    loadChildren: () => import('./pages/_settings/settings.routes').then( (m) => m.routes),
  },

];
