import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { LoginService } from '../login.service';
import { routes } from 'src/app/pages/cca/cca.routes';
import { urls } from '../../config/urls';
import { Observable, map, take } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserLoggedInGuard {

    constructor(private readonly loginService: LoginService, private readonly router: Router) {}

    canActivate( _next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> {

        // Utiliza el observable de isLoggedIn$ para obtener el estado de autenticación
        return this.loginService.isLoggedIn$.pipe(
            // Toma el primer valor emitido y completa el observable
            take(1),
            map((isUserLoggedIn: unknown) => {
                if (isUserLoggedIn) {
                    // Permitir acceso
                    return true;
                }
                // Si no está autenticado o la ruta no es válida, redirigir a '/cca'
                console.log('Routing to notAuthorized');
                return this.router.parseUrl(urls.notAuthorized);
            }),
        );
    }
    private checkRouteValidity(route: ActivatedRouteSnapshot): boolean {
        const segments = route.url;
        // Obtener la ruta completa
        const routePath = segments.map((segment) => segment.path).join('/');
        // Verificar si la ruta está definida en routes
        const matchingRoute = routes.find((el) => el.path === routePath);
        // Devuelve true si se encuentra una coincidencia en routes
        return !!matchingRoute;
    }
}
