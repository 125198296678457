import { Routes } from '@angular/router';
import { CcaInstalacionRequired } from 'src/app/globalcampo/services/guards/cca.instalacion-required.guard';
import { CcaInstalacionExplotacionRequired } from 'src/app/globalcampo/services/guards/cca.intalacion-explotacion-required.guard';
import { CcaSiexAuthorization } from 'src/app/globalcampo/services/guards/cca.siex-auth.guard';
import { withoutUnsavedChangesGuard } from 'src/app/globalcampo/services/guards/without-unsaved-changes.guard';

export const routes: Routes = [
  {
    path: 'dgc-detail/:id',
    loadComponent: () => import('./dgc-detail/dgc-detail.page').then((m) => m.DgcDetailPage),
  },
  {
    path: '',
    loadComponent: () => import('./cca.page').then((m) => m.CcaPage),
  },
  {
    path: 'detail',
    loadComponent: () => import('../../pages/globalclima/globalclima.page').then((m) => m.GlobalclimaPage),
  },
  {
    canActivate: [CcaInstalacionRequired],
    path: 'buscar',
    loadComponent: () => import('./buscar/buscar.page').then((m) => m.BuscarPage),
  },
  {
    path: 'instalaciones',
    loadComponent: () => import('./instalaciones/instalaciones.page').then((m) => m.InstalacionesPage),
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    path: 'dashboard',
    loadComponent: () => import('./dashboard/dashboard.page').then((m) => m.DashboardPage),
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    path: 'general',
    loadComponent: () => import('./general/general.page').then((m) => m.GeneralPage)
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    path: 'titular',
    loadComponent: () => import('./titular/titular.page').then((m) => m.TitularPage)
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    path: 'parcelas',
    loadComponent: () => import('./parcelas/parcelas.page').then((m) => m.ParcelasPage)
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    path: 'trabajadores',
    loadComponent: () => import('./trabajadores/trabajadores.page').then((m) => m.TrabajadoresPage),
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    path: 'actividades',
    loadComponent: () => import('./actividades/actividades.page').then((m)=> m.ActividadesPage),
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/tratamiento-fitosanitario',
    loadComponent: () => import('../../components/cca/tratamiento-fitosanitario/tratamiento-fitosanitario.component').then((m) => m.TratamientoFitosanitarioComponent),
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/tratamiento-fitosanitario/:id',
    loadComponent: () => import('../../components/cca/tratamiento-fitosanitario/tratamiento-fitosanitario.component').then((m) => m.TratamientoFitosanitarioComponent),
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/fertilizacion',
    loadComponent: () => import('../../components/cca/fertilizacion/fertilizacion.component').then((m) => m.FertilizacionComponent),
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/fertilizacion/:id',
    loadComponent: () => import('../../components/cca/fertilizacion/fertilizacion.component').then((m) => m.FertilizacionComponent),
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/edificaciones-instalaciones',
    loadComponent: () => import('../../components/cca/tratamiento-edificacion/tratamiento-edificacion.component').then((m) => m.TratamientoEdificacionComponent),
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/edificaciones-instalaciones/:id',
    loadComponent: () => import('../../components/cca/tratamiento-edificacion/tratamiento-edificacion.component').then((m) => m.TratamientoEdificacionComponent),
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/analitica',
    loadComponent: () => import('../../components/cca/analitica/analitica.component').then((m) => m.AnaliticaComponent),
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/analitica/:id',
    loadComponent: () => import('../../components/cca/analitica/analitica.component').then((m) => m.AnaliticaComponent),
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/cosecha',
    loadComponent: () => import('../../components/cca/cosecha/cosecha.component').then((m) => m.CosechaComponent),
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/cosecha/:id',
    loadComponent: () => import('../../components/cca/cosecha/cosecha.component').then((m) => m.CosechaComponent),
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/datos-cubierta',
    loadComponent: () => import('../../components/cca/datos-cubierta/datos-cubierta.component').then((m) => m.DatosCubiertaComponent),
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/datos-cubierta/:id',
    loadComponent: () => import('../../components/cca/datos-cubierta/datos-cubierta.component').then((m) => m.DatosCubiertaComponent),
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/pastoreo',
    loadComponent: () => import('../../components/cca/pastoreo/pastoreo.component').then((m) => m.PastoreoComponent),
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/pastoreo/:id',
    loadComponent: () => import('../../components/cca/pastoreo/pastoreo.component').then((m) => m.PastoreoComponent),
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/post-cosecha',
    loadComponent: () => import('../../components/cca/post-cosecha/post-cosecha.component').then((m) => m.PostCosechaComponent)
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/post-cosecha/:id',
    loadComponent: () => import('../../components/cca/post-cosecha/post-cosecha.component').then((m) => m.PostCosechaComponent)
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/plan-abonado',
    loadComponent: () => import('../../components/cca/plan-abonado/plan-abonado.component').then((m) => m.PlanAbonadoComponent)
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/plan-abonado/:id',
    loadComponent: () => import('../../components/cca/plan-abonado/plan-abonado.component').then((m) => m.PlanAbonadoComponent)
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/semilla-tratada',
    loadComponent: () => import('../../components/cca/uso-semilla-tratada/uso-semilla-tratada.component').then((m) => m.UsoSemillaTratadaComponent)
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/semilla-tratada/:id',
    loadComponent: () => import('../../components/cca/uso-semilla-tratada/uso-semilla-tratada.component').then((m) => m.UsoSemillaTratadaComponent)
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/comercializacion-venta-directa',
    loadComponent: () => import('../../components/cca/comercializacion-venta-directa/comercializacion-venta-directa.component').then((m) => m.ComercializacionVentaDirectaComponent)
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/comercializacion-venta-directa/:id',
    loadComponent: () => import('../../components/cca/comercializacion-venta-directa/comercializacion-venta-directa.component').then((m) => m.ComercializacionVentaDirectaComponent)
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/siembra-plantacion',
    loadComponent: () => import('../../components/cca/siembra-plantacion/siembra-plantacion.component').then((m) => m.SiembraPlantacionComponent)
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/siembra-plantacion/:id',
    loadComponent: () => import('../../components/cca/siembra-plantacion/siembra-plantacion.component').then((m) => m.SiembraPlantacionComponent)
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/energia-utilizada',
    loadComponent: () => import('../../components/cca/energia-utilizada/energia-utilizada.component').then((m) => m.EnergiaUtilizadaComponent),
  },
  {
    canActivate: [CcaInstalacionExplotacionRequired, CcaSiexAuthorization],
    canDeactivate: [withoutUnsavedChangesGuard],
    path: 'actividades/energia-utilizada/:id',
    loadComponent: () => import('../../components/cca/energia-utilizada/energia-utilizada.component').then((m) => m.EnergiaUtilizadaComponent),
  },
];
