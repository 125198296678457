import { addIcons } from 'ionicons';
import { IGlobalcampoIcons, TGlobalcampoIcon, TIconType } from '../interfaces/globalcampo.icon.interface';

const assetsGCPath = '/assets/icon/gc/';
const assetsGeneralPath = '/assets/icon/gc/cca/';
const assetsActividadPath = '/assets/icon/gc/cca/actividad/';
const assetsMenuCCA = '/assets/icon/gc/cca/menu/';
const assetsFormsPath = '/assets/icon/gc/forms/';

const buildActividadPath = (folder: string, name: string) => `${assetsActividadPath}${folder}/${name}.svg`;
const buildGeneralPath = (folder: string, name: string) => `${assetsGeneralPath}${folder}/${name}.svg`;
const buildFormsPath = (folder: string, name: string) => `${assetsFormsPath}${folder}/${name}.svg`;
const buildNewPath = (folder: string, name: string) => `${assetsGCPath}${folder}/${name}.svg`;
const buildMenuCCAPath = (folder: string, name: string) => `${assetsMenuCCA}${folder}/${name}.svg`;

const menuCCAHeaderFolder = 'header';
const gcCuadernoDigitalName= 'gc-cuaderno-digital';
export const gcCuadernoDigitalSolid = { [gcCuadernoDigitalName]: buildMenuCCAPath(menuCCAHeaderFolder, 'gc-folder') };
const gcCompactMenuName= 'gc-double-arrow';
export const gcCompactMenuSolid = { [gcCompactMenuName]: buildMenuCCAPath(menuCCAHeaderFolder, 'gc-double-arrow') };
const menuCCADashboardFolder = 'dashboard';
const gcDashboardName = 'gc-home';
const gcDashboardOutlineName = `${gcDashboardName}-outline`;
export const gcDashboardSolid = { [gcDashboardName]: buildMenuCCAPath(menuCCADashboardFolder, 'gc-home') };
export const gcDashboardOutline = { [gcDashboardOutlineName]: buildMenuCCAPath(menuCCADashboardFolder, 'gc-home-outline') };
const menuCCAActividadesFolder = 'actividades';
const gcActividadesName = 'gc-clipboard';
const gcActividadesOutlineName = `${gcActividadesName}-outline`;
export const gcActividadesSolid = { [gcActividadesName]: buildMenuCCAPath(menuCCAActividadesFolder, 'gc-clipboard') };
export const gcActividadesOutline = { [gcActividadesOutlineName]: buildMenuCCAPath(menuCCAActividadesFolder, 'gc-clipboard-outline') };
const menuCCATitularFolder = 'titular';
const gcTitularName = 'gc-farmer';
const gcTitularOutlineName = `${gcTitularName}-outline`;
export const gcTitularSolid = { [gcTitularName]: buildMenuCCAPath(menuCCATitularFolder, 'gc-farmer') };
export const gcTitularOutline = { [gcTitularOutlineName]: buildMenuCCAPath(menuCCATitularFolder, 'gc-farmer-outline') };
const menuCCAExpFolder = 'explotacion';
const gcExpName = 'gc-barn';
const gcExpOutlineName = `${gcExpName}-outline`;
export const gcExpSolid = { [gcExpName]: buildMenuCCAPath(menuCCAExpFolder, 'gc-barn') };
export const gcExpOutline = { [gcExpOutlineName]: buildMenuCCAPath(menuCCAExpFolder, 'gc-barn-outline') };
const menuCCATrabFolder = 'trabajadores';
const gcTrabName = 'gc-users';
const gcTrabOutlineName = `${gcTrabName}-outline`;
export const gcTrabSolid = { [gcTrabName]: buildMenuCCAPath(menuCCATrabFolder, 'gc-users') };
export const gcTrabOutline = { [gcTrabOutlineName]: buildMenuCCAPath(menuCCATrabFolder, 'gc-users-outline') };
const menuCCAParcFolder = 'parcelario';
const gcParcName = 'gc-map';
const gcParcOutlineName = `${gcParcName}-outline`;
export const gcParcSolid = { [gcParcName]: buildMenuCCAPath(menuCCAParcFolder, 'gc-map') };
export const gcParcOutline = { [gcParcOutlineName]: buildMenuCCAPath(menuCCAParcFolder, 'gc-map-outline') };
const menuCCAFooterFolder = 'footer';
const gcExitName= 'gc-out-arrow';
export const gcExitSolid = { [gcExitName]: buildMenuCCAPath(menuCCAFooterFolder, 'gc-out-arrow') };

const generalFolder = 'general';
const gcGrapesName = 'gc-grapes';
const gcGrapesOutlineName = `${gcGrapesName}-outline`;
export const gcGrapesSolid = { [gcGrapesName]: buildGeneralPath(generalFolder, 'gc-grape') };
export const gcGrapesOutline = { [gcGrapesOutlineName]: buildGeneralPath(generalFolder, 'gc-grape-outline') };

const cosechaFolder = 'cosecha';
const gcCosechaName = 'gc-cosecha';
const gcCosechaOutlineName = `${gcCosechaName}-outline`;
export const gcCosechaSolid = { [gcCosechaName]: buildActividadPath(cosechaFolder, gcCosechaName) };
export const gcCosechaOutline = { [gcCosechaOutlineName]: buildActividadPath(cosechaFolder, gcCosechaOutlineName) };

const gcFitosanitarioFolder = 'fitosanitario';
const gcFitosanitarioName = 'gc-fitosanitario';
const gcFitosanitarioOutlineName = `${gcFitosanitarioName}-outline`;
export const gcFitosanitarioSolid = { [gcFitosanitarioName]: buildActividadPath(gcFitosanitarioFolder, gcFitosanitarioName) };
export const gcFitosanitarioOutline = { [gcFitosanitarioOutlineName]: buildActividadPath(gcFitosanitarioFolder, gcFitosanitarioOutlineName) };

const gcEdificacionesFolder = 'edificaciones-instalaciones';
const gcEdificacionesName = 'gc-edificaciones';
const gcEdificacionesOutlineName = `${gcEdificacionesName}-outline`;
export const gcEdificacionesSolid = { [gcEdificacionesName]: buildActividadPath(gcEdificacionesFolder, 'gc-edificaciones-e-instalaciones') };
export const gcEdificacionesOutline = { [gcEdificacionesOutlineName]: buildActividadPath(gcEdificacionesFolder, 'gc-edificaciones-e-instalaciones-outline') };

const gcUsoSemillaTratadaFolder = 'semilla-tratada';
const gcUsoSemillaTratadaName = 'gc-uso-semilla-tratada';
const gcUsoSemillaTratadaOutlineName = `${gcUsoSemillaTratadaName}-outline`;
export const gcUsoSemillaTratadaSolid = { [gcUsoSemillaTratadaName]: buildActividadPath(gcUsoSemillaTratadaFolder, 'gc-semilla-tratada') };
export const gcUsoSemillaTratadaOutline = { [gcUsoSemillaTratadaOutlineName]: buildActividadPath(gcUsoSemillaTratadaFolder, 'gc-semilla-tratada-outline') };

const gcPostCosechaFolder = 'post-cosecha';
const gcPostCosechaName = 'gc-post-cosecha';
const gcPostCosechaOutlineName = `${gcPostCosechaName}-outline`;
export const gcPostCosechaSolid = { [gcPostCosechaName]: buildActividadPath(gcPostCosechaFolder, gcPostCosechaName) };
export const gcPostCosechaOutline = { [gcPostCosechaOutlineName]: buildActividadPath(gcPostCosechaFolder, gcPostCosechaOutlineName) };

const gcFertilizacionFolder = 'fertilizacion';
const gcFertilizacionName = 'gc-fertilizacion';
const gcFertilizacionOutlineName = `${gcFertilizacionName}-outline`;
export const gcFertilizacionSolid = { [gcFertilizacionName]: buildActividadPath(gcFertilizacionFolder, gcFertilizacionName) };
export const gcFertilizacionOutline = { [gcFertilizacionOutlineName]: buildActividadPath(gcFertilizacionFolder, gcFertilizacionOutlineName) };

const gcAnaliticaFolder = 'analitica';
const gcAnaliticaName = 'gc-analitica';
const gcAnaliticaOutlineName = `${gcAnaliticaName}-outline`;
export const gcAnaliticaSolid = { [gcAnaliticaName]: buildActividadPath(gcAnaliticaFolder, gcAnaliticaName) };
export const gcAnaliticaOutline = { [gcAnaliticaOutlineName]: buildActividadPath(gcAnaliticaFolder, gcAnaliticaOutlineName) };

const gcSiembraPlantacionFolder = 'siembra-plantacion';
const gcSiembraPlantacionName = 'gc-siembra-plantacion';
const gcSiembraPlantacionOutlineName = `${gcSiembraPlantacionName}-outline`;
export const gcSiembraPlantacionSolid = { [gcSiembraPlantacionName]: buildActividadPath(gcSiembraPlantacionFolder, 'gc-siembra-y-plantacion') };
export const gcSiembraPlantacionOutline = { [gcSiembraPlantacionOutlineName]: buildActividadPath(gcSiembraPlantacionFolder, 'gc-siembra-y-plantacion-outline') };

const gcComercializacionVentaFolder = 'comercializacion-venta-directa';
const gcComercializacionVentaName = 'gc-comercializacion-venta';
const gcComcercializacionVentaOutlineName = `${gcComercializacionVentaName}-outline`;
export const gcComcercializacionVentaSolid = { [gcComercializacionVentaName]: buildActividadPath(gcComercializacionVentaFolder, 'gc-comercializacion-y-venta-directa') };
export const gcComcercializacionVentaOutline = {
    [gcComcercializacionVentaOutlineName]: buildActividadPath(gcComercializacionVentaFolder, 'gc-comercializacion-y-venta-directa-outline'),
};

const gcDatosCubiertaFolder = 'datos-cubierta';
const gcDatosCubiertaName = 'gc-datos-cubierta';
const gcDatosCubiertaOutlineName = `${gcDatosCubiertaName}-outline`;
export const gcDatosCubiertaSolid = { [gcDatosCubiertaName]: buildActividadPath(gcDatosCubiertaFolder, 'gc-datos-de-cubierta') };
export const gcDatosCubiertaOutline = { [gcDatosCubiertaOutlineName]: buildActividadPath(gcDatosCubiertaFolder, 'gc-datos-de-cubierta-outline') };

const gcEnergiaUtilizadaFolder = 'energia-utilizada';
const gcEnergiaUtilizadaName = 'gc-energia-utilizada';
const gcEnergiaUtilizadaOutlineName = `${gcEnergiaUtilizadaName}-outline`;
export const gcEnergiaUtilizadaSolid = { [gcEnergiaUtilizadaName]: buildActividadPath(gcEnergiaUtilizadaFolder, gcEnergiaUtilizadaName) };
export const gcEnergiaUtilizadaOutline = { [gcEnergiaUtilizadaOutlineName]: buildActividadPath(gcEnergiaUtilizadaFolder, gcEnergiaUtilizadaOutlineName) };

const gcPastoreoFolder = 'pastoreo';
const gcPastoreoName = 'gc-pastoreo';
const gcPastoreoOutlineName = `${gcPastoreoName}-outline`;
export const gcPastoreoSolid = { [gcPastoreoName]: buildActividadPath(gcPastoreoFolder, gcPastoreoName) };
export const gcPastoreoOutline = { [gcPastoreoOutlineName]: buildActividadPath(gcPastoreoFolder, gcPastoreoOutlineName) };

const gcActividadAgrariaFolder = 'actividad-agraria';
const gcActividadAgrariaName = 'gc-actividad-agraria';
const gcActividadAgrariaOutlineName = `${gcActividadAgrariaName}-outline`;
export const gcActividadAgrariaSolid = { [gcActividadAgrariaName]: buildActividadPath(gcActividadAgrariaFolder, gcActividadAgrariaName) };
export const gcActividadAgrariaOutline = { [gcActividadAgrariaOutlineName]: buildActividadPath(gcActividadAgrariaFolder, gcActividadAgrariaOutlineName) };

const gcPlanAbonadoFolder = 'plan-abonado';
const gcPlanAbonadoName = 'gc-plan-abonado';
const gcPlanAbonadoOutlineName = `${gcPlanAbonadoName}-outline`;
export const gcPlanAbonadoSolid = { [gcPlanAbonadoName]: buildActividadPath(gcPlanAbonadoFolder, 'gc-plan-de-abonado') };
export const gcPlanAbonadoOutline = { [gcPlanAbonadoOutlineName]: buildActividadPath(gcPlanAbonadoFolder, 'gc-plan-de-abonado-outline') };

const gcLockFolder = 'lock';
const gcLockSolidName = 'gc-lock';
export const gcLockSolid = { [gcLockSolidName]: buildFormsPath(gcLockFolder, gcLockSolidName) };

const newFolder = 'add';
const gcAddFolderName = 'gc-nueva-actividad';
export const gcAddFolderSolid = { [gcAddFolderName]: buildNewPath(newFolder, 'gc-add-folder') };
const gcAddMapName = 'gc-nueva-parcela';
export const gcAddMapSolid = { [gcAddMapName]: buildNewPath(newFolder, 'gc-add-map') };
const gcAddUserName = 'gc-nuevo-trabajador';
export const gcAddUserSolid = { [gcAddUserName]: buildNewPath(newFolder, 'gc-add-user') };
const gcAddTracktorName = 'gc-nueva-maquinaria';
export const gcAddTracktorSolid = { [gcAddTracktorName]: buildNewPath(newFolder, 'gc-add-tracktor') };

const idFolder = 'id';
const gcIdName = 'gc-id-name';
export const gcIdNameSolid = { [gcIdName]: buildNewPath(idFolder, gcIdName) };
export const gcIdNameOutline = { [gcIdName]: buildActividadPath(idFolder, `${gcIdName}-outline`) };
const gcIdNumber = 'gc-id-number';
export const gcIdNumberSolid = { [gcIdNumber]: buildNewPath(idFolder, gcIdNumber) };
export const gcIdNumberOutline = { [gcIdNumber]: buildActividadPath(idFolder, `${gcIdNumber}-outline`) };

export const globalcampoIconos: IGlobalcampoIcons = {
    uva: {
        solid: {
            name: gcGrapesName,
            iconData: gcGrapesSolid,
        },
        outline: {
            name: gcGrapesOutlineName,
            iconData: gcGrapesOutline,
        },
    },
    cosecha: {
        solid: {
            name: gcCosechaName,
            iconData: gcCosechaSolid,
        },
        outline: {
            name: gcCosechaOutlineName,
            iconData: gcCosechaOutline,
        },
    },
    fitosanitario: {
        solid: {
            name: gcFitosanitarioName,
            iconData: gcFitosanitarioSolid,
        },
        outline: {
            name: gcFitosanitarioOutlineName,
            iconData: gcFitosanitarioOutline,
        },
    },
    edificaciones: {
        solid: {
            name: gcEdificacionesName,
            iconData: gcEdificacionesSolid,
        },
        outline: {
            name: gcEdificacionesOutlineName,
            iconData: gcEdificacionesOutline,
        },
    },
    usoSemillaTratada: {
        solid: {
            name: gcUsoSemillaTratadaName,
            iconData: gcUsoSemillaTratadaSolid,
        },
        outline: {
            name: gcUsoSemillaTratadaOutlineName,
            iconData: gcUsoSemillaTratadaOutline,
        },
    },
    postCosecha: {
        solid: {
            name: gcPostCosechaName,
            iconData: gcPostCosechaSolid,
        },
        outline: {
            name: gcPostCosechaOutlineName,
            iconData: gcPostCosechaOutline,
        },
    },
    fertilizacion: {
        solid: {
            name: gcFertilizacionName,
            iconData: gcFertilizacionSolid,
        },
        outline: {
            name: gcFertilizacionOutlineName,
            iconData: gcFertilizacionOutline,
        },
    },
    analitica: {
        solid: {
            name: gcAnaliticaName,
            iconData: gcAnaliticaSolid,
        },
        outline: {
            name: gcAnaliticaOutlineName,
            iconData: gcAnaliticaOutline,
        },
    },
    siembraPlantacion: {
        solid: {
            name: gcSiembraPlantacionName,
            iconData: gcSiembraPlantacionSolid,
        },
        outline: {
            name: gcSiembraPlantacionOutlineName,
            iconData: gcSiembraPlantacionOutline,
        },
    },
    comercializacionVenta: {
        solid: {
            name: gcComercializacionVentaName,
            iconData: gcComcercializacionVentaSolid,
        },
        outline: {
            name: gcComcercializacionVentaOutlineName,
            iconData: gcComcercializacionVentaOutline,
        },
    },
    datosCubierta: {
        solid: {
            name: gcDatosCubiertaName,
            iconData: gcDatosCubiertaSolid,
        },
        outline: {
            name: gcDatosCubiertaOutlineName,
            iconData: gcDatosCubiertaOutline,
        },
    },
    energiaUtilizada: {
        solid: {
            name: gcEnergiaUtilizadaName,
            iconData: gcEnergiaUtilizadaSolid,
        },
        outline: {
            name: gcEnergiaUtilizadaOutlineName,
            iconData: gcEnergiaUtilizadaOutline,
        },
    },
    pastoreo: {
        solid: {
            name: gcPastoreoName,
            iconData: gcPastoreoSolid,
        },
        outline: {
            name: gcPastoreoOutlineName,
            iconData: gcPastoreoOutline,
        },
    },
    actividadAgraria: {
        solid: {
            name: gcActividadAgrariaName,
            iconData: gcActividadAgrariaSolid,
        },
        outline: {
            name: gcActividadAgrariaOutlineName,
            iconData: gcActividadAgrariaOutline,
        },
    },
    planAbonado: {
        solid: {
            name: gcPlanAbonadoName,
            iconData: gcPlanAbonadoSolid,
        },
        outline: {
            name: gcPlanAbonadoOutlineName,
            iconData: gcPlanAbonadoOutline,
        },
    },
    lock: {
        solid: {
            name: gcLockSolidName,
            iconData: gcLockSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    nuevaActividad: {
        solid: {
            name: gcAddFolderName,
            iconData: gcAddFolderSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    nuevaParcela: {
        solid: {
            name: gcAddMapName,
            iconData: gcAddMapSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    nuevoTrabajador: {
        solid: {
            name: gcAddUserName,
            iconData: gcAddUserSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    nuevaMaquinaria: {
        solid: {
            name: gcAddTracktorName,
            iconData: gcAddTracktorSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    idName: {
        solid: {
            name: gcIdName,
            iconData: gcIdNameSolid,
        },
        outline: {
            name: `${gcIdName}-outline`,
            iconData: gcIdNameOutline,
        },
    },
    idNumber: {
        solid: {
            name: gcIdNumber,
            iconData: gcIdNumberSolid,
        },
        outline: {
            name: `${gcIdNumber}-outline`,
            iconData: gcIdNumberOutline,
        },
    },
    cuadernoDigital: {
        solid: {
            name: gcCuadernoDigitalName,
            iconData: gcCuadernoDigitalSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    compactArrow: {
        solid: {
            name: gcCompactMenuName,
            iconData: gcCompactMenuSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    dashboard: {
        solid: {
            name: gcDashboardName,
            iconData: gcDashboardSolid,
        },
        outline: {
            name: gcDashboardOutlineName,
            iconData: gcDashboardOutline,
        },
    },
    actividades: {
        solid: {
            name: gcActividadesName,
            iconData: gcActividadesSolid,
        },
        outline: {
            name: gcActividadesOutlineName,
            iconData: gcActividadesOutline,
        },
    },
    titular: {
        solid: {
            name: gcTitularName,
            iconData: gcTitularSolid,
        },
        outline: {
            name: gcTitularOutlineName,
            iconData: gcTitularOutline,
        },
    },
    explotacion: {
        solid: {
            name: gcExpName,
            iconData: gcExpSolid,
        },
        outline: {
            name: gcExpOutlineName,
            iconData: gcExpOutline,
        },
    },
    trabajadores: {
        solid: {
            name: gcTrabName,
            iconData: gcTrabSolid,
        },
        outline: {
            name: gcTrabOutlineName,
            iconData: gcTrabOutline,
        },
    },
    parcelario: {
        solid: {
            name: gcParcName,
            iconData: gcParcSolid,
        },
        outline: {
            name: gcParcOutlineName,
            iconData: gcParcOutline,
        },
    },
    exitArrow: {
        solid: {
            name: gcExitName,
            iconData: gcExitSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
};

/**
 * Inicializa los iconos globalcampo al set de iconos de ionic
 */
export const initGlobalcampoIcons = () => {
    for (const iconNameKey in globalcampoIconos) {
        if (globalcampoIconos[iconNameKey]) {
            const iconValue = globalcampoIconos[iconNameKey];
            addIconTypes(iconValue);
        }

    }
};

/**
 * Añade los tipos de un icono al set de iconos de ionic
 * @param iconValue
 */
const addIconTypes = (iconValue: TIconType) => {
    for (const iconTypeKey in iconValue) {
        if (iconValue[iconTypeKey]) {
            const iconType = iconValue[iconTypeKey];
            addIcons(iconType.iconData);
        }
    }
};

/**
 * Añade los iconos indicados al set de iconos de ionic
 * @param icons
 */
export const addCustomIcons = (icons: TGlobalcampoIcon[]): void => {
    icons.forEach((icon) => {
        addIcons(icon);
    });
};
